const dwpPensionRates: DwpPensionRatesWithAllowances[] = [
  {
    taxYear: '2020/21',
    weeklyBasicStatePension: 134.25,
    weeklyFullStatePension: 175.2,
    annualAllowance: 40000,
    lifetimeAllowance: 1073100,
  },
  {
    taxYear: '2021/22',
    weeklyBasicStatePension: 137.6,
    weeklyFullStatePension: 179.6,
    annualAllowance: 40000,
    lifetimeAllowance: 1073100,
  },
  {
    taxYear: '2022/23',
    weeklyBasicStatePension: 141.85,
    weeklyFullStatePension: 185.15,
    annualAllowance: 40000,
    lifetimeAllowance: 1073100,
  },
  {
    taxYear: '2022/23-july',
    weeklyBasicStatePension: 141.85,
    weeklyFullStatePension: 185.15,
    annualAllowance: 40000,
    lifetimeAllowance: 1073100,
  },
  {
    taxYear: '2022/23-november',
    weeklyBasicStatePension: 141.85,
    weeklyFullStatePension: 185.15,
    annualAllowance: 40000,
    lifetimeAllowance: 1073100,
  },
  {
    taxYear: '2023/24',
    weeklyBasicStatePension: 156.2,
    weeklyFullStatePension: 203.85,
    annualAllowance: 40000,
    lifetimeAllowance: 1073100,
  },
];

export default dwpPensionRates;
