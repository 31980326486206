export const Locales = {
  England: 'GB-ENG',
  Scotland: 'GB-SCT',
  Wales: 'GB-WLS',
  NorthernIreland: 'GB-NIR',
} as const;

export const TaxBands = {
  Starter: 'starter',
  Basic: 'basic',
  Intermediate: 'intermediate',
  Higher: 'higher',
  Additional: 'additional',
} as const;
