import { TaxBands } from '../constants';

const englandTaxRates: TaxRatesWithAllowance[] = [
  {
    taxYear: '2020/21',
    bands: [
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Higher,
        rate: 0.4,
        earningsFrom: 37500,
      },
      {
        name: TaxBands.Additional,
        rate: 0.45,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12500,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2021/22',
    bands: [
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Higher,
        rate: 0.4,
        earningsFrom: 37700,
      },
      {
        name: TaxBands.Additional,
        rate: 0.45,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2022/23',
    bands: [
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Higher,
        rate: 0.4,
        earningsFrom: 37700,
      },
      {
        name: TaxBands.Additional,
        rate: 0.45,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2022/23-july',
    bands: [
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Higher,
        rate: 0.4,
        earningsFrom: 37700,
      },
      {
        name: TaxBands.Additional,
        rate: 0.45,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2022/23-november',
    bands: [
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Higher,
        rate: 0.4,
        earningsFrom: 37700,
      },
      {
        name: TaxBands.Additional,
        rate: 0.45,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2023/24',
    bands: [
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Higher,
        rate: 0.4,
        earningsFrom: 37700,
      },
      {
        name: TaxBands.Additional,
        rate: 0.45,
        earningsFrom: 125140,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
];

export default englandTaxRates;
