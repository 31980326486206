import { Controller } from 'stimulus';
import { ResizeObserver } from '@juggle/resize-observer';
import { CountUp } from 'countup.js';
import { incomeTax, DWP, Locales } from "../../javascript/packages/calculator-toolkit-v2.1.0/dist/calculator-toolkit.production.cjs.min";

// Import UJS so we can access the Rails.ajax method
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'chart',
    'chartContainer',
    'segmentGroup',
    'segment',
    'segmentValue',
    'segmentOuter',
    'marker',
    'clip',
    'mask',
    'label',
    'value',
    'scoreBreakdown',
    'onTrackText',
    'shortfallMoreThanTwoYearsText',
    'shortfallLessThanTwoYearsText',
    'scoreShortfall',
    'shortfallLabel',
    'shortfallValue',
    'breakdownTargetIncome',
    'breakdownPensionAfterTax',
    'lifestyleQuestionWrapper',
    'lifestyleQuestion',
    'livingStandardQuestion',
    'previousQuestionLink',
    'nextQuestionLink',
    'viewScoreLink',
    'progress',
    'progressMax',
    'progressBar',
    'scoreGroup',
    'questionError',
    'lifestyleResult',
    'livingStandardOutput',

    // debug related:
    'targetIncome',
    'useScottishRates',
    'totalCompanyPension',
    'totalPension',
    'pensionAfterTax',
    'score',
  ];

  initialize() {
    this.taxYear = this.data.get('taxYear');
    this.livingStandards = {
      minimum: 12800,
      moderate: 23300,
      comfortable: 37300,
    };

    this.lifestyleChoices = [];

    this.selectedLivingStandard = null;

    const width = this.data.get('width');
    const radius = this.data.get('radius');

    this.chartTarget.setAttribute('viewBox',`0 0 ${width} ${width}`);
    this.segmentGroupTarget.setAttribute('transform', `rotate(-90 ${width / 2} ${width / 2})`);

    this.segmentTargets.forEach((element) => {
      element.setAttribute('r', `${radius}%`);
    });

    this.segmentOuterTarget.setAttribute('r', `${this.data.get('outerRadius')}%`);

    this.clipTarget.setAttribute('width',`${width / 2}`);
    this.clipTarget.setAttribute('height',`${width / 2}`);

    this.maskTarget.setAttribute('width',`${width / 2}`);
    this.maskTarget.setAttribute('height',`${width / 2}`);

    this.scoreCounter = new CountUp(this.valueTarget, 0, { duration: 1.4 });
    this.shortfallCounter = new CountUp(this.shortfallValueTarget, 0, { duration: 1, prefix: '£' });
    this.targetIncomeCounter = new CountUp(this.breakdownTargetIncomeTarget, 0, { duration: 1, prefix: '£' });
    this.pensionAfterTaxCounter = new CountUp(this.breakdownPensionAfterTaxTarget, 0, { duration: 1, prefix: '£' });

    this.transformChart(0);
    const existingScore = parseInt(this.data.get('value')) / 100;
    if (existingScore > 0) {
      setTimeout(() => {
        this.selectedLivingStandard = this.data.get('livingStandard');
        this.selectRadioButton('living-standard', this.selectedLivingStandard);
        this.calculate(existingScore);
      }, 200);
    } else {
      this.labelTarget.innerText = 'Choose a retirement living standard to see your score';
      this.valueTarget.classList.add('hide');
      this.scoreShortfallTarget.classList.add('hide');
    }

    this.progressMax = this.lifestyleQuestionTargets.length + 1; // number of questions + result screen

    this.closeToRetirement = this.data.get('closeToRetirement') === 'true';
    this.debug = this.data.get('debug') === 'true';

    this.observeResize();
  }

  get circumference() {
    return this.calculateCircumference(this.data.get('radius'));
  }

  get outerCircumference() {
    return this.calculateCircumference(this.data.get('outerRadius'));
  }

  calculateCircumference(radius) {
    return 2 * Math.PI * parseInt(radius) / 100 * parseInt(this.data.get('width'));
  }

  chooseLivingStandard(e) {
    const radioElement = e.target;
    this.selectRadioButton('living-standard', radioElement.value);
    this.selectedLivingStandard = radioElement.value;
    this.calculate();
  }

  calculate(scoreOverride) {
    const fullAnnualStatePension = DWP.ratesForTaxYear(this.taxYear).weeklyFullStatePension * 52;
    const annualPensionBeforeTax = parseFloat(this.data.get('totalPension')) + fullAnnualStatePension;
    const locale = this.data.get('useScottishRates') === 'true' ? Locales.Scotland : Locales.England;
    const annualPensionAfterTax = annualPensionBeforeTax - incomeTax.annualPayable(annualPensionBeforeTax, this.taxYear, locale).total;

    const requiredIncomeInRetirement = this.livingStandards[this.selectedLivingStandard];
    const score = scoreOverride !== undefined ? scoreOverride : Math.min(1, annualPensionAfterTax / requiredIncomeInRetirement);

    this.transformChart(score);
    this.updateCopy(score);
    this.save(score);

    this.animateFigure(this.scoreCounter, score * 100);
    this.animateFigure(this.shortfallCounter, annualPensionAfterTax - requiredIncomeInRetirement);
    this.animateFigure(this.targetIncomeCounter, requiredIncomeInRetirement);
    this.animateFigure(this.pensionAfterTaxCounter, annualPensionAfterTax);

    if (this.debug) {
      this.targetIncomeTarget.innerText = requiredIncomeInRetirement.toFixed(2);
      this.useScottishRatesTarget.innerText = this.data.get('useScottishRates');
      this.totalCompanyPensionTarget.innerText = parseFloat(this.data.get('totalPension')).toFixed(2);
      this.totalPensionTarget.innerText = annualPensionBeforeTax.toFixed(2);
      this.pensionAfterTaxTarget.innerText = annualPensionAfterTax.toFixed(2);
      this.scoreTarget.innerText = ((annualPensionAfterTax / requiredIncomeInRetirement) * 100).toFixed(2);
    }
  }

  animateFigure(target, value) {
    if (!target.error) {
      target.update(value);
    } else {
      console.error(target.error);
    }
  }

  // IE11 doesn't support CSS transformations on SVG elements, so we need to also set the SVG transform attribute
  transformChart(scoreValue) {
    this.segmentValueTarget.setAttribute('stroke-dasharray', `${scoreValue * this.circumference} ${this.circumference}`);
    this.segmentOuterTarget.setAttribute('stroke-dasharray', `${scoreValue * this.outerCircumference} ${this.outerCircumference}`);
    this.markerTarget.setAttribute('transform', `translate(42) rotate(${360 * scoreValue}, 8, ${this.data.get('width') / 2})`);
    this.markerTarget.setAttribute('style', `transform: translate(42px) rotate(${360 * scoreValue}deg); transform-origin: 8px ${this.data.get('width') / 2}px`);

    const maskRotationStyle = `transform: rotate(${360 * scoreValue + 90}deg); transform-origin: ${this.data.get('width') / 2}px ${this.data.get('width') / 2}px`;
    const maskRotationTransform = `rotate(${360 * scoreValue + 90}, ${this.data.get('width') / 2}, ${this.data.get('width') / 2})`;

    this.clipTarget.setAttribute('transform', maskRotationTransform);
    this.maskTarget.setAttribute('transform', maskRotationTransform);

    this.clipTarget.setAttribute('style', maskRotationStyle);
    this.maskTarget.setAttribute('style', maskRotationStyle);
  }

  resetScore() {
    this.calculate(0);
  }

  updateCopy(score) {
    this.valueTarget.classList.remove('hide');
    this.labelTarget.innerText = 'Your score:';

    // trigger conditional copy visibility
    if (this.hasOnTrackTextTarget) {
      this.toggleClass(this.onTrackTextTarget, 'hide', score < 0.9);
    }
    if (this.closeToRetirement && this.hasShortfallLessThanTwoYearsTextTarget) {
      this.toggleClass(this.shortfallLessThanTwoYearsTextTarget, 'hide', score > 0.9);
    }
    if (!this.closeToRetirement && this.hasShortfallMoreThanTwoYearsTextTarget) {
      this.toggleClass(this.shortfallMoreThanTwoYearsTextTarget, 'hide', score > 0.9);
    }

    this.scoreShortfallTarget.classList.remove('hide');
    this.toggleClass(this.shortfallValueTarget, 'score__label--shortfall', score < 1);
    this.toggleClass(this.shortfallValueTarget, 'score__label--surplus', score >= 1);
    this.shortfallLabelTarget.innerText = score >= 1 ? 'Surplus' : 'Shortfall';
  }

  // classlist.toggle doesn't accept a second parameter in IE 11 so this ends up being more verbose
  toggleClass(element, className, condition) {
    if (condition === true) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }

  save(score) {
    // a jQuery ajax post or Axios would convert to www-form-urlencoded content type automatically
    // however using Rails UJS ajax post nicely handles the X-CSRF-Token stuff for us
    // we need to therefore post the data with manual conversion
    Rails.ajax({
      type: 'post',
      url: '/widget_data',
      data: `widget_data[score]=${Math.round(score * 100)}&widget_data[living_standard]=${this.selectedLivingStandard}&widget_data[statement_year]=${this.taxYear}`
    });

    if(window.gtag) {
      //console.log("Score interaction logged")
      window.gtag('event', "interaction", {
        'event_category': 'set_score',
        'event_label': this.selectedLivingStandard
      })
    }
  }

  toggleHelpQuestions(e) {
    e.preventDefault();

    this.scoreGroupTargets.forEach((el) => {
      el.classList.remove('score__content--with-delay');
      el.classList.add('score__content--invisible');
    });

    this.lifestyleQuestionWrapperTarget.classList.add('score__content--with-delay');
    this.lifestyleQuestionWrapperTarget.classList.remove('score__content--invisible');
    this.lifestyleResultTarget.classList.add('hide');
    this.nextQuestionLinkTarget.classList.remove('hide');

    this.showQuestion(0);
    this.progressMaxTarget.innerText = this.progressMax;
  }

  toggleLivingStandardQuestion(e) {
    e.preventDefault();
    this.lifestyleQuestionWrapperTarget.classList.remove('score__content--with-delay');
    this.lifestyleQuestionWrapperTarget.classList.add('score__content--invisible');

    this.scoreGroupTargets.forEach((el) => {
      el.classList.add('score__content--with-delay');
      el.classList.remove('score__content--invisible');
    });
  }

  nextQuestion(e) {
    e.preventDefault();
    // check if a radio button is checked before proceeding to the next question
    let hasChecked = false;
    this.lifestyleQuestionTargets[this.index].querySelectorAll('input[type=radio]').forEach((radioElement) => {
      if (radioElement.checked) {
        hasChecked = true;
      }
    });
    if (hasChecked) {
      if (this.index === this.lifestyleQuestionTargets.length - 1) {
        // if we're already on the last question, display the living standard
        this.calculateLivingStandard();
      } else {
        this.showQuestion(this.index + 1);
      }
    } else {
      this.questionErrorTarget.innerText = 'Please choose an option.';
    }
  }

  previousQuestion(e) {
    e.preventDefault();
    this.questionErrorTarget.innerText = '';
    this.showQuestion(this.index - 1)
  }

  showQuestion(index) {
    this.index = index;
    this.lifestyleQuestionTargets.forEach((el, i) => {
      this.toggleClass(el, 'score__question--hidden', index !== i);
    });

    // determine action button visibility
    this.toggleClass(this.previousQuestionLinkTarget, 'hide', this.index === 0);
    this.viewScoreLinkTarget.classList.add('hide');

    this.updateProgressBar(index + 1);
  }

  updateProgressBar(index) {
    // update progress indicator
    this.progressTarget.innerText = index;
    this.progressBarTarget.style.width = `${index / (this.lifestyleQuestionTargets.length + 1) * 100}%`;
  }

  answerLifestyleQuestion(e) {
    this.questionErrorTarget.innerText = '';
    document.querySelectorAll(`input[name="${e.target.getAttribute('name')}"]`).forEach((element) => {
      this.selectRadioButton(e.target.getAttribute('name'), e.target.value);
    });
    this.lifestyleChoices[this.index] = e.target.value;
  }

  selectRadioButton(groupName, value) {
    document.querySelectorAll(`input[name="${groupName}"]`).forEach((element) => {
      element.checked = value === element.value;
      this.toggleClass(element.parentNode, 'selected', value === element.value);
    });
  }

  calculateLivingStandard() {
    this.updateProgressBar(this.progressMax);

    // calcute living standard using a points based approach using radio button value
    // assume each response is as follows: minimum = 1, moderate = 2 and comfortable = 3
    let livingStandardScore = 0;
    this.selectedLivingStandard = this.lifestyleQuestionWrapperTarget.querySelectorAll('.score__radio:checked').forEach(radioElement => {
      switch(radioElement.value) {
        case 'minimum':
          livingStandardScore += 1;
          break;
        case 'moderate':
          livingStandardScore += 2;
          break;
        case 'comfortable':
          livingStandardScore += 3;
          break;
        }
      }
    );

    if (livingStandardScore >= 7) {
      this.selectedLivingStandard = 'comfortable';
    } else if (livingStandardScore >= 4) {
      this.selectedLivingStandard = 'moderate';
    } else {
      this.selectedLivingStandard = 'minimum';
    }

    this.livingStandardOutputTarget.innerText = this.selectedLivingStandard;

    this.calculate(0); // reset dial so we can animate it in again
    this.animateFigure(this.shortfallCounter, 0);
    this.animateFigure(this.targetIncomeCounter, 0);

    this.nextQuestionLinkTarget.classList.add('hide');
    this.previousQuestionLinkTarget.classList.add('hide');
    this.nextQuestionLinkTarget.classList.add('hide');
    this.viewScoreLinkTarget.classList.remove('hide');
    this.lifestyleResultTarget.classList.remove('hide');
    this.lifestyleQuestionTargets[this.lifestyleQuestionTargets.length - 1].classList.add('score__question--hidden');
  }

  calculateScoreFromLifestyle(e) {
    this.selectRadioButton('living-standard', this.selectedLivingStandard);
    this.toggleLivingStandardQuestion(e);

    setTimeout(() => {
      this.calculate();
    }, 600);
  }

  // IE 11 specific as it doesn't handle responsive inline SVGs as expected
  observeResize() {
    if (window.document.documentMode) {
      const observer = new ResizeObserver((entries, observer) => {
        entries.forEach((entry, index) => {
          const { inlineSize: width } = entry.contentBoxSize[0];

          this.chartTarget.setAttribute('style', `width: ${width}px; height: ${width}px`);
        });
      });
      observer.observe(this.chartContainerTarget); // Watch for dimension changes
    }
  }
}
